import { Component, OnDestroy, OnInit } from '@angular/core';
import { appName } from '@msslib/constants/app-name';
import { FooterComponent } from '@msslib/components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { ToastComponent } from '@msslib/components/toast/toast.component';
import { ModalComponent } from '@msslib/components/modal/modal.component';
import { ModalTemplateDirective } from '@msslib/components/modal/modal-template.directive';
import { LoadingComponent } from '@msslib/components/loading/loading.component';
import { UserpilotService } from '@msslib/services/userpilot.service';

@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    LoadingComponent,
    ModalTemplateDirective,
    ModalComponent,
    ToastComponent,
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  public constructor(
    private userpilotService: UserpilotService,
  ) { }

  public ngOnInit() {
    (window as any).appName = appName.lenderHub;
    this.userpilotService.run();
  }

  public ngOnDestroy(): void {
    this.userpilotService.stop();
  }
}
